.row {
    display: flex;
    margin-bottom: 6vh;
    align-items: center;
    justify-content: flex-end;
}

.rowContainer {
    width: 60%;
    margin-left: 2vh;
}

.rowHeading {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 7px;
}

.rowDesc {
    font-weight: 400;
    font-size: 16px;
}

.contentHead {
    margin-top: 20vh;
}

.bgGrid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.serviceGridMain1 {
    margin-top: 8vh !important;
}

.testimonialHead {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.gridStyleTest1 {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
}

.feather6 {
    position: absolute;
    left: 32vw;
    width: 45px
}

.feather5 {
    position: absolute;
    top: -3vw;
    right: 16vw;
    width: 45px
}

@media screen and (max-width:480px) {

    .row {
        justify-content: center;
    }

    .feather6 {
        left: 12vw;
        width: 24px
    }

    .feather5 {
        top: -10vw;
        right: 5vw;
        width: 26px
    }

    .contentHead {
        margin-top: 10vh;
    }

    .serviceGridMain1 {
        margin-top: 2vh !important;
        flex-direction: column !important;
    }

    .bgGrid {
        padding: 0 !important;
        margin-top: -6vh;
        max-width: 107% !important;
    }

    .serviceGrid {
        max-width: 100% !important;
        padding: 0 !important;
    }

    .rowHeading {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 6px;
    }

    .rowDesc {
        font-weight: 400;
        font-size: 12px;
    }

    .testimonialHead {
        margin-top: 0vh;
        margin-bottom: 0vh;
    }

    .serviceGridMain2 {
        flex-direction: column !important;
    }

    .gridStyleTest1 {
        max-width: 100% !important;
        margin-top: 7vh !important;
    }
}

@media screen and (min-height: 1960px) {
    .serviceGridMain2 {
        flex-direction: column !important;
        align-items: center !important;
    }

    .gridStyleTest1 {
        margin-top: 3vh !important;
        max-width: 70% !important;
    }
}

@media screen and (min-width:480px) and (max-width:1080px) {
    .serviceGridMain2 {
        flex-direction: column !important;
        align-items: center !important;
    }

    .gridStyleTest1 {
        margin-top: 5vh !important;
        max-width: 70% !important;
    }
}

@media screen and (max-width:300px) {
    .gridStyleTest1 {
        margin-top: 5vh !important;
        max-width: 100% !important;
    }
}

@media screen and (min-width:480px) and (max-width:1300px) {
    .rowHeading {
        font-size: 19px;
    }

    .rowDesc {
        font-size: 19px;
    }

    .serviceGrid {
        margin-top: 3vh !important;
    }
}

@media screen and (min-height:1500px)and (max-height:3000px) {
    .contentHead {
        margin-top: 7vh;
    }

    .serviceGridMain1 {
        margin-top: 2vh !important;
    }

    .testimonialHead {
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
}