.feather8 {
    position: absolute;
    top: 0px;
    right: 12vw;
    width: 81px
}

@media screen and (max-width:480px) {
    .feather8 {
        right: 6vw;
        width: 45px;
    }
}