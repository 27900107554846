@font-face {
    font-family: gogh;
    src: url(../../Fonts/gogh/Gogh-ExtraBold.ttf);
}

.customFont {
    font-family: gogh !important;
}

.grid1 {
    display: flex;
    align-items: flex-start;
    justify-content: center !important;
    flex-direction: column !important;
    padding-left: 7vw !important;
}


.mainContactBtn {
    background: #E9EDC9;
}

.mainContactBtn:hover {
    background: #DEE5BE;
}

.mainContactBtn:active {
    background: #CCD5AE;
}

.feather2 {
    width: 68px;
    margin-left: 40vw;
}

.feather1 {
    width: 42px;
    position: absolute;
    left: -1vw;
    top: 14vh;
}

@media screen and (max-width:480px) {
    .MainBg {
        width: 80vw !important;
    }

    .grid1 {
        width: 80%;
        transition: 0.8s all ease;
    }

    .grid1 {
        width: 100%;
        max-width: 100% !important;
        transition: 0.8s all ease;
        padding: 0 !important;
    }

    .feather1 {
        width: 24px;
        top: 10vh;
    }

    .feather2 {
        width: 40px;
        left: -1vw;
    }

    .gridMain {
        padding: 0 !important;
        margin: 0 !important;
        flex-direction: column-reverse !important;
    }
}


.subHead {
    font-weight: 400;
    font-size: 19px;
}

@media screen and (max-width:300px) {
    .subHead {
        font-weight: 400;
        font-size: 12px;
    }
}
/* 
.marquee {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    max-width: 100%;
}

.marquee span {
    display: inline-block;
    padding-left: 107%;
    animation: marquee 20s linear infinite;
} */

@media screen and (max-width:480px) {
    .subHead {
        font-size: 15px;
    }
}




@media screen and (min-width:520px) and (max-width:1024px) {
    .subHead {
        font-size: 15px;
    }
}

@media screen and (min-width:1024px) and (max-width:1300px) {
    .subHead {
        font-size: 17px;
    }
}


.marquee {
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  
  .marquee-inner {
    display: block;
    width: 200%;
    position: absolute;
    animation: marquee 10s linear infinite;
  }
  
  .marquee-inner > span {
    float: left;
    width: 50%;
    overflow: hidden;
  }
  
  @keyframes marquee {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }
  