.bg {
    background: #CCD5AE;
    padding-top: 0 !important;
    z-index: 9999 !important;
    -webkit-tap-highlight-color: transparent !important;
}

.dialogContent {
    padding: 0 !important;
    width: 65vw;
    background: #CCD5AE;
    overflow-y: hidden !important;
}

.ContactGrid2 {
    width: 45% !important;
    display: flex;
    align-items: flex-start;

}

.ContactGrid1 {
    margin-right: 6%;
}

.ContactGrid1D {
    display: flex !important;
    align-items: flex-start !important;
}

.customContactbg {
    margin-top: -180px;
}

.submitBtnEnabled {
    color: #1E1E1E !important;
    background: #FEFAE0 !important;
}

.submitBtnEnabled:hover {
    background: #FCF5D7 !important;
}

.submitBtnEnabled:active {
    background: #FAEDCD !important;
}

.submitBtnDisabled {
    color: #1e1e1e82 !important;
    background: #FEFAE0 !important;
    cursor: not-allowed !important;
}

.contactForm {
    height: 300px !important;
    margin-top: 65px !important;
    padding-top: 0 !important;
}

.contactHead {
    font-weight: 600;
    font-size: 50px;
    margin-left: 10vw;
}

.contactHeadMain {
    font-weight: 600;
    font-size: 50px;
    margin-left: 10vw;
}

.contactHeadForm {
    font-weight: 600;
    font-size: 50px;
    margin-left: 4vw;
}

.contactHeadM {
    margin-bottom: 20px;
}

.inputField {
    border: 2px solid #696E59;
    border-radius: 20px !important;
}

.submitBtn1 {
    margin-top: 25px !important;
    color: #1E1E1E;
    width: 207px;
    height: 65px;
    border-radius: 20px;
    border: none;
    font-size: 21px;
    margin-top: 40px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.submitBtn2 {
    display: none !important;
}

.contactSubHead {
    font-weight: 400;
    font-size: 21px;
}

.contactLogo {
    position: absolute;
    top: 2vh;
    left: 4vw;
    margin-left: 0vw !important;
}

.contactBtn {
    font-weight: 100;
    color: #1E1E1E;
    background: #FEFAE0;
}

.contactBtn:hover {
    background: #FCF5D7;
}

.contactBtn:active {
    background: #FAEDCD;
}

.submitBtn1 {
    font-weight: 100;
    color: #1E1E1E;
    background: #FEFAE0;
}

.submitBtn2 {
    font-weight: 100;
    color: #1E1E1E;
    background: #FEFAE0;
}

.submitBtn2:hover {
    background: #FCF5D7;
}

.submitBtn12:active {
    background: #FAEDCD;
}

.closeIcon {
    position: absolute;
    top: 30px;
    right: 26px;
    z-index: 11;
    cursor: pointer;
    font-size: 20px;
    color: #7a7a7a;
}

.emailInput {
    border: 2px solid #696E59;
    border-radius: 20px !important;
}

.msgInputProps {
    border: 2px solid #696E59;
    border-radius: 20px !important;
}

.inputPart1 {
    width: 100%;
    max-width: 100%;
    line-height: 5;
}

.feather7 {
    position: absolute;
    top: 3.5vw;
    left: 14vw;
    width: 55px;
}

.email {
    width: 48%;
}

.name {
    width: 48%;
    margin-right: 4% !important;
}


@media screen and (max-width:1724px) {
    .contactHeadForm {
        font-size: 42px;
    }

    .contactSubHead {
        font-size: 17px;
    }

    .submitBtn {
        width: 180px !important;
        height: 56px !important;
    }
}

@media screen and (max-width:1462px) {

    .contactLogo {
        left: 2vw;
    }

    .contactHeadForm {
        margin-left: 2vw;
    }

    .ContactGrid2 {
        width: 46% !important;
    }

}

@media screen and (max-width:1348px) {

    .contactHeadForm {
        margin-left: 1vw;
        font-size: 38px;
    }

    .contactSubHead {
        font-size: 15px;
    }

    .ContactGrid2 {
        width: 48% !important;
    }

    .submitBtn {
        width: 170px !important;
        height: 50px !important;
    }

    .inputField {
        height: 48px !important;
    }

    .msgInputProps {
        height: 100px;
        padding-top: 42px !important;
    }

    .contactForm {
        height: 315px !important;
    }

    .inputPart1 {
        line-height: 4;
    }
}

@media screen and (max-width:1280px) {
    .contactHeadMain {
        font-size: 42px;
    }
}


@media screen and (max-width:378px) {
    .dialogContent {
        width: 100%;
        height: 74vh !important;
    }

    .customContactbg {
        margin-top: -0px;
    }
}

@media screen and (max-width:480px) {
    .closeIcon{
        top: 27px;
        right: 21px;
    }

    .mainContact {
        align-items: center !important;
    }

    .customContactbg {
        margin-top: -10px !important;
    }

    .dialogContent {
        width: 100%;
        height: 65vh;
    }

    .contactForm {
        height: 75% !important;
        margin-top: 35px !important;
    }

    .contactBtn {
        margin-top: 26px !important;
    }

    .contactHeadMain {
        font-size: 35px;
        margin-left: 0vw;
    }

    .submitBtn1 {
        display: none !important;
    }

    .submitBtn2 {
        display: flex !important;
        margin-top: 20px !important;
        font-size: 14px !important;
        color: #1E1E1E;
        width: 140px;
        height: 45px;
        border-radius: 20px;
        border: none;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .contactHeadForm {
        margin-left: 1vw;
        font-size: 28px;
    }

    .ContactGrid2 {
        margin-top: 3vh;
        width: 90% !important;
    }

    .contactHeadM {
        margin-bottom: 10px;
    }

    .email {
        width: 100%;
    }

    .name {
        width: 100%;
        margin-right: 4% !important;
    }

    .feather7 {
        left: 20vw;
        width: 35px;
    }

    .inputField {
        height: 5vh;
    }

    .inputPart1 {
        line-height: 4;
    }

    .contactLogo {
        left: 7vw;
        width: 26vw;
        top: 1vh;
    }

    /* .contactHead {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
    } */

    .contactSubHead {
        font-weight: 400;
        font-size: 12px;
    }

    .email {
        margin-left: 0 !important;
    }
}


@media screen and (min-width:480px)and (max-width:700px) {
    .dialogContent {
        width: 100%;
        height: 80vh;
    }

    .contactForm {
        height: 75% !important;
        margin-top: 35px !important;
        flex-direction: column;
    }

    .contactHeadMain {
        font-size: 35px;
        margin-left: 0vw;
    }

    .submitBtn1 {
        display: none !important;
    }

    .submitBtn2 {
        display: flex !important;
        margin-top: 20px !important;
        font-size: 14px !important;
    }

    .contactHeadForm {
        margin-left: 1vw;
        font-size: 28px;
    }

    .ContactGrid2 {
        width: 90% !important;
    }

    .ContactGrid1 {
        margin-top: 3vh;
        width: 100% !important;
    }

    .contactHeadM {
        margin-bottom: 10px;
    }

    .email {
        width: 100%;
    }

    .name {
        width: 100%;
        margin-right: 4% !important;
    }

}

@media screen and (min-width:700px)and (max-width:840px) {
    .dialogContent {
        width: 100%;
        height: 32vh;
    }

    .contactSubHeadDialog {
        font-size: 13px;
    }
}

@media screen and (min-width:840px)and (max-width:960px) {
    .dialogContent {
        width: 100%;
        height: 46vh;
    }

    .contactHeadForm {
        margin-left: 1vw;
        font-size: 32px;
    }

    .ContactGrid2 {
        width: 48% !important;
    }

    .submitBtn1 {
        width: 157px !important;
        height: 40px !important;
        border-radius: 16px !important;
        font-size: 11px !important;
    }

}


@media screen and (max-height:500px) {

    .inputField {
        height: 10vh;
    }

}

.input {
    border: 2px solid #696E59;
    border-radius: 20px !important;
}

fieldset {
    border-color: rgb(0 0 0 / 0%) !important;
}

@media screen and (min-width: 540px) and (max-width: 540px) {}

@media screen and (min-height: 1960px) {
    .contactHead {
        font-size: 60px;
    }

    .contactSubHead {
        font-weight: 400;
        font-size: 30px;
    }

    .inputPart1 {
        line-height: 7;
    }

    .inputField {
        height: 3vh;
    }

    .msgInput {
        height: 5vh;
    }
}

@media screen and (min-width:480px) and (max-width:1100px) {
    .contactHeadM {
        font-size: 34px;
    }

    .contactSubHead {
        font-size: 14px;
    }
}

@media screen and (min-width:1100px) and (max-width:1300px) {
    .contactHeadM {
        font-size: 42px;
    }

    .contactSubHead {
        font-size: 17px;
    }

    .contactSubHeadDialog {
        font-size: 14px;
    }

    .contactHeadDialog {
        font-size: 36px;
    }

    .submitBtn1D {
        width: 130px !important;
        font-size: 16px !important;
        height: 42px !important;
        border-radius: 18px !important;
    }
}

@media screen and (min-height:1500px)and (max-height:3000px) {
    .contactLogo {
        top: 1vh;
        left: 2vw;
    }
}