.gridAbout {
    width: 60% !important;
    margin-top: 8vh !important;
}


.gridStyle {
    width: 100%;
    max-width: 33.33333%;
    background: #FAEDCD;
    display: flex;
    justify-content: center;
    align-items: 'center';
}

.logoConnelly {
    width: 182px !important;
}

.logoHansen {
    width: 241px !important;
}

.logoReynolds {
    width: 237px !important;
}

.feather3 {
    position: absolute;
    top: -1vw;
    left: 0vw;
    width: 40px;
}

.feather3>p {
    font-size: 16px;
}

.feather4 {
    margin-left: 80vw;
    width: 70px;
}

@media screen and (min-height:1500px)and (max-height:3000px) {
    .gridAbout {
        margin-top: 4vh !important;
    }
}

@media screen and (max-width:480px) {
    .gridAbout {
        line-height: 50px;
        margin-top: 1vh !important;
        flex-direction: column !important;
    }

    .gridStyle {
        max-width: 100% !important;
        width: 100% !important;
    }

    .logoConnelly {
        width: 100px !important;
    }

    .logoHansen {
        width: 105px !important;
    }

    .logoReynolds {
        width: 110px !important;
    }

    .feather3 {
        left: 2vw;
        width: 20px;
    }

    .feather4 {
        margin-left: 70vw;
        width: 42px;
    }

}

@media screen and (min-width:480px) and (max-width:1300px) {
    .logoConnelly {
        width: 150px !important;
    }

    .logoHansen {
        width: 170px !important;
    }

    .logoReynolds {
        width: 190px !important;
    }
}